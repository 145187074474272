<template>
  <div v-if="checkPermission('list-campaign', 'action', 'campaign-reports')" id="reports-campaign-list">
    <topic-component :topic="$t('SideBarItems.reports.list.reportCampaigns')"/>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-card no-body class="mb-4">
            <b-row>
              <b-col lg="4" md="6" sm="12">
                <b-form-group label-cols="12"
                              :label="$t('common.dateTime')" label-for="input-sm">
                  <date-time-range-component v-model="time"></date-time-range-component>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="3" sm="4">
                <select-filter-component :label-select="$t('report.campaignList.campaignType')"
                                         :options="typeList"
                                         v-model="form.type"/>
              </b-col>
              <b-col lg="4" md="3" sm="4">
                <select-filter-component :label-select="$t('report.campaignList.status')"
                                         :options="statusList"
                                         v-model="form.status"/>
              </b-col>
              <b-col lg="4" md="3" sm="4">
                <b-form-group label-cols="12"
                              :label="$t('report.campaignList.campaignId')" label-for="input-sm">
                  <b-form-input v-model="form.campaign_id"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="4" md="3" sm="4">
                <b-form-group label-cols="12"
                              :label="$t('report.campaignList.campaignName')" label-for="input-sm">
                  <b-form-input v-model="form.campaign_name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col style="margin-top: 35px">
                <button type="button" class="btn waves-effect waves-light btn-gradient " @click="findCampaigns">
                  {{ $t('common.Search') }}
                </button>
                <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
                  {{ $t('common.resetSearch') }}
                </button>
              </b-col>
            </b-row>
            <hr>
            <b-row align-v="center" class="pb-3">
              <b-col lg="6" md="6" sm="12">
                <div class="d-flex align-items-center">
                  <span class="mr-3">{{ $t('common.perPage') }}</span>
                  <div>
                    <b-form-select
                        v-model="form.$limit"
                        :options="pageOptions"
                        size="sm"
                        @change="findCampaigns"
                    />
                  </div>
                  <div class="ml-3">
                    <total-list-component :total="totalAll"/>
                  </div>
                </div>
              </b-col>
              <b-col align="end" lg="6" md="6" sm="12" class="custom-col-button">
                <b-button variant="primary" class="mr-2" @click="exportData"
                          v-if="checkPermission('export-campaign', 'action', 'campaign-reports')">
                  <i class="fas fa-file-excel "></i>
                  {{ $t("button.export") }}
                </b-button>
                <b-button :disabled="loadCampaign" variant="primary" @click="getRefundAllCampaign"
                          v-if="checkPermission('request-refund', 'action', 'campaign-reports')">
                  <span> <img
                      :src="require('@/assets/images/icon/refund-campagin.svg')">  {{ $t("button.refundAllCampaign") }}</span>
                  <span v-if="loadCampaign" style="padding-left: 6px"><b-spinner
                      style="width: 18px; height: 18px"></b-spinner></span>
                </b-button>
              </b-col>
            </b-row>
            <b-table
                responsive
                class="mb-0"
                :items="items"
                :fields="fields"
                :busy="isBusy"
                :empty-text="$t('empty')"
                show-empty
            >
              <template #head()="{ label, field: { key, sortable }}">
                {{ $t(label) }}
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2"></b-spinner>
                  <strong> {{ $t('loading') }}</strong>
                </div>
              </template>
              <template #empty="scope">
                <empty-table/>
              </template>
              <template #cell(createdAt)="data">
                {{ changeDateFormat(data.item.createdAt) }}
              </template>
              <template #cell(scheduled)="data">
                {{ changeDateFormat(data.item.scheduled) }}
              </template>
              <template #cell(contacts)="data">
                <span class="text-bold text-blue">{{ changeNumberFormat(data.item.contacts) }}</span>
              </template>
              <template #cell(credit_used_amount)="data">
                <span class="text-bold text-primary ">{{ changeNumberFormat(data.item.credit_used_amount) }}</span>
              </template>
              <template #cell(send_type)="data">
                <span>  {{ filterValue(sendType, data.item.send_type) }}</span>
              </template>
              <template #cell(campaign_type)="data">
                <b-badge pill
                         :class="`px-2 text-white badge ${data.item.campaign_type === 'normal' ? 'bg-info' :'bg-primary' }`">
                  {{ filterValue(typeList, data.item.campaign_type) }}
                </b-badge>
              </template>
              <template #cell(status)="data">
                <b-badge pill :class="colorButton(data.item.status)" class="px-2 text-white badge">
                  {{ filterValue(statusList, data.item.status) }}
                </b-badge>
              </template>
              <template #cell(send_date)="data">
                {{ changeDateFormat(data.item.send_date) }}
              </template>
              <template #cell(schedule_date)="data">
                {{ changeDateFormat(data.item.schedule_date) }}
              </template>
              <template #cell(campaign_timer)="data">
                <div class="campaign-timer">
                  {{ checkIsProcessing(data.item) }}
                </div>

              </template>
              <template #cell(use_tag_url)="data">
                <div :class="data.item.use_tag_url ? 'text-green' : 'text-danger' ">
                  {{ data.item.use_tag_url ? $t('common.active') : $t('common.inactive') }}
                </div>
              </template>
              <template #cell(action)="data">
                <div class="flex-row-center" style="flex-wrap: nowrap;">
                  <a :href="`/Reports/Campaigns/Detail?campaign=${data.item._id}&${strQuery}`">
                    <i style="color: #0d73d9" class="action-button icon-eye mr-2"
                       @click.prevent="viewDetail(data.item._id)"
                       v-if="checkPermission('view-campaign', 'action', 'campaign-reports')"></i>
                  </a>
                  <i
                      v-if="data.item.can_cancel && checkPermission('cancel-campaign', 'action', 'campaign-reports')"
                      style="color: #df3f1e"
                      class="action-button far fa-stop-circle mr-2"
                      v-b-tooltip.hover
                      :title="$t('common.cancel')"
                      @click="cancelCampaign(data.item._id)"
                  ></i>
                  <b-button variant="primary" class="flex-row-center" style="padding: 4px 12px"
                            @click="reTargetModal(data.item._id)"
                            v-if="hideButtonRetarget(data.item) && checkPermission('create', 'action', 'send-using-file')">
                    <img :src="require('@/assets/images/icon/re-target.svg')">
                    <span class="ml-2">Retarget</span>
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findCampaigns"
          ></b-pagination>
        </div>
      </b-row>
    </b-card>
    <b-modal
        id="modal-refund-all-campaign"
        ok-variant="info"
        size="lg"
        hide-footer
        no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5></h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div class="refund-campaign-container">
        <div class="w-100 flex-column-center" style="gap: 16px">
          <p class="m-0 text-primary title-font">
            {{ $t('report.campaignList.modalRefundAllCredit.confirmRefundAllCredit') }}</p>
          <div class="w-100 flex-column-center" style="gap: 8px; ">
           <span class="w-100 flex-row-center sub-title-font">
             <p class="m-0">{{ $t('report.campaignList.modalRefundAllCredit.availableRefundCredit') }}</p>
             <span class="ml-1" style="text-transform: lowercase">{{ refund_total }} {{
                 $t('calendar.campaign')
               }}</span>
           </span>
            <span class="w-100 flex-row-center sub-title-font">
              <p class="m-0">{{ $t('report.campaignList.modalRefundAllCredit.totalRefundCredit') }}</p>
              <span class="ml-1 text-primary" style="text-transform: lowercase">{{
                  refund_credit
                }} {{ $t('subscriptions.customPackage.credit') }}</span>
            </span>
          </div>
        </div>
        <div class="grid-all-refund-campaign mt-3" id="grid-all-refund-campaign">
          <div v-for="campaign in displayedData" :key="campaign.id" class="grid-refund-item">
            {{ campaign }}
          </div>
        </div>
        <div class="footer flex-column-center w-100 mt-3" style="gap: 12px">
          <div class="flex-row-center w-100" style="justify-content: flex-end">
            <b-pagination
                v-model="refundCurrentPage"
                :total-rows="refund_total"
                :per-page="refundPageSize"
            ></b-pagination>
          </div>
          <div class="flex-row-center w-100" style="gap: 18px">
            <button style="padding: 8px 16px; width: 200px" type="button"
                    class="btn waves-effect waves-light btn-outline ml-2" @click="closeCampaignModal">
              {{ $t('common.cancel') }}
            </button>
            <button style="padding: 8px 16px; width: 200px; gap: 8px" type="button"
                    :disabled="buttonLoading"
                    class="btn waves-effect waves-light btn-gradient flex-row-center" @click="sendAllCampaign">
              <span>{{ $t('common.confirm') }}</span>
              <span v-if="buttonLoading"><b-spinner style="width: 18px; height: 18px"></b-spinner></span>
            </button>
          </div>
          <div class="flex-row-center w-100 mt-1" style="gap: 18px">
            <p class="mb-3 text-danger" style="font-size: 14px;">{{
              $t('report.campaignList.modalRefundAllCredit.hint')
              }}</p>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
        id="modal-export-report"
        ok-variant="info"
        hide-footer
        no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5></h5>
        <i class="fas fa-times hover-icon action-button" @click="close"></i>
      </template>
      <div align="center" v-if="!exportSuccess">
        <b-spinner
            class="mt-5 mb-1"
            style="width: 4rem; height: 4rem"
            label="Large Spinner"
            variant="warning"
            type="grow">
        </b-spinner>
        <h3 v-html="$t('processing')" class="mb-3"></h3>
      </div>
      <div v-if="exportSuccess">
        <div align="center" v-if="exportSuccess&&step_success">
          <i class="fas fa-check-circle display-4 mb-3 d-block text-success mt-3 mb-2"/>
          <h3 v-html="$t('success')" class="mb-3"></h3>
          <div v-for="items in fileExport">
            <a :href="items">{{ items }}</a>
            <hr/>
          </div>
        </div>
        <div align="center" v-if="!exportSuccess&&step_success">
          <i class="fas fa-times-circle display-4 mb-3 d-block text-danger mt-3 mb-2"/>
          <h3 v-html="$t('failed')" class="mb-3"></h3>
        </div>
      </div>
    </b-modal>
    <retarget :loading="reTargetLoading" :obj="returnReTarget"/>
  </div>
</template>

<script>
import {format} from "date-fns";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent";
import TopicComponent from "@/components/common/topicComponent";
import reportApi from "@/repository/reportApi";
import moment from "moment";
import masterData from "@/common/masterData.json";
import functionsCommon from "@/common/functions";
import functions from "@/common/functions";
import alert from "@/common/alert";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import Retarget from "@/views/reports/campaign/retarget.vue";
import smsTemplatesApi from "@/repository/smsTemplatesApi";
import authApi from "@/repository/authApi";
import {store} from "@/store";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "campaign",
  components: {TotalListComponent, Retarget, EmptyTable, SelectFilterComponent, TopicComponent, DateTimeRangeComponent},
  data: () => ({
    totalAll: 0,
    grid: null,
    returnReTarget: null,
    reTargetLoading: false,
    buttonLoading: false,
    timer: new Date(),
    refund_credit: 0,
    refund_total: 0,
    all_campaign_refund: [],
    loadCampaign: false,
    refundCurrentPage: 1,
    refundPageSize: 30,
    form: {
      date_from: null,
      date_to: null,
      campaign_id: '',
      campaign_name: '',
      status: null,
      type: null,
      $skip: 1,
      $limit: 10,
    },
    isBusy: false,
    exportSuccess: false,
    step_success: false,
    fileExport: [],
    typeList: masterData.campaignTypeList,
    sendType: masterData.sendType,
    time: [
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
    ],
    fields: [
      {
        key: "createdAt",
        label: "report.campaignList.createdAt",
      },
      {
        key: "campaign_id",
        label: "report.campaignList.campaignId",
      },
      {
        key: "campaign_name",
        label: "report.campaignList.campaignName",
      },
      {
        key: "contacts",
        label: "report.campaignList.contacts",
      },
      {
        key: "credit_used_amount",
        label: "report.campaignList.credit",
      },
      {
        key: "send_type",
        label: "report.messageList.typeSend",
      },
      {
        key: "campaign_type",
        label: "report.campaignList.campaignType",
      },
      {
        key: "schedule_date",
        label: "report.campaignList.scheduledDate",
      },
      {
        key: "use_tag_url",
        label: "Tracking2Pro",
      },
      {
        key: "status",
        label: "report.campaignList.status",
      },
      {
        key: "send_date",
        label: "report.campaignList.deliveredAt",
      },
      {
        key: "campaign_timer",
        label: "report.campaignList.campaign_timer",
      },
      {
        key: "action",
        label: "report.campaignList.action",
      },

    ],
    items: [],
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
    pageOptions: [10, 15, 50, 100],
    statusList: masterData.campaignStatusList,
    strQuery: {}
  }),
  mounted() {
    this.findCampaigns()
  },
  created() {
    this.time = this.$route.query.time1 ? [
      new Date(moment(this.$route.query.time1).format('YYYY-MM-DD') + 'T' + moment(this.$route.query.time1).format('HH:mm:ss')),
      new Date(moment(this.$route.query.time2).format('YYYY-MM-DD') + 'T' + moment(this.$route.query.time2).format('HH:mm:ss'))
    ] : [
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
      new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
    ]
    this.form.campaign_id = this.$route.query.campaign_id ? this.$route.query.campaign_id : ''
    this.form.campaign_name = this.$route.query.campaign_name ? this.$route.query.campaign_name : ''
    this.form.status = this.$route.query.status ? this.$route.query.status : null
    this.form.type = this.$route.query.type ? this.$route.query.type : null
    // this.findCampaigns()
  },
  computed: {
    displayedData() {
      const startIndex = (this.refundCurrentPage - 1) * this.refundPageSize;
      const endIndex = startIndex + this.refundPageSize;
      const checkLength = this.all_campaign_refund.slice(startIndex, endIndex)
      if (checkLength.length === 1) {
        this.grid?.classList.remove('modal-refund-all-campaign__custom-grid-two')
        this.grid?.classList.add('modal-refund-all-campaign__custom-grid-one')
      } else if (checkLength.length === 2) {
        this.grid?.classList.remove('modal-refund-all-campaign__custom-grid-one')
        this.grid?.classList.add('modal-refund-all-campaign__custom-grid-two')
      } else {
        this.grid?.classList.remove('modal-refund-all-campaign__custom-grid-one')
        this.grid?.classList.remove('modal-refund-all-campaign__custom-grid-two')
      }
      return this.all_campaign_refund.slice(startIndex, endIndex);
    },
  },
  methods: {
    format,
    checkIsProcessing(item) {
      return item?.campaign_timer ? item?.campaign_timer + ' ' + `${this.$t('common.minute')}` : item.status !== 'cancelled' ? this.$t('common.processing') : '-'
    },
    hideButtonRetarget(item) {
      return !_.isNil(item?.campaign_timer) && item.status !== 'cancelled' && item?.status !== 'new'
    },
    reTargetModal(id) {
      this.reTargetLoading = true
      this.$bvModal.show('modal-retarget-campaign')
      reportApi.getCampaignById(id).then(resp => {
        this.returnReTarget = resp
      }).catch(() => {
        this.$bvModal.hide('modal-retarget-campaign')
      }).finally(() => {
        this.reTargetLoading = false
      })
    },
    warningRefundCampaign(title = null, message = null, time = null) {
      this.$swal.fire({
        icon: 'warning',
        title: title,
        html: message !== null ? '<p style="white-space: pre-wrap;">' + message + ' ' + '<span class="text-primary">' + this.formatTimeByLocale(time) + '</span>' + '</p>' : false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.$t('button.confirm'),
      })
    },
    errorRefundCampaign(message = null) {
      this.$swal.fire({
        icon: 'error',
        title: this.$t('report.campaignList.modalRefundAllCredit.failRefund'),
        text: message !== null ? message : false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: this.$t('button.confirm'),
      })
    },
    formatTimeByLocale(time = null) {
      if (time != null) {
        if (this.$i18n.locale !== "th") {
          return this.format(time, 'hh:mm aaa.')
        } else {
          return `${this.format(time, 'HH:mm')} น.`
        }
      }
      return null
    },
    getRefundAllCampaign() {
      this.loadCampaign = true
      reportApi.getCampaignAllRefund().then(response => {
        this.$bvModal.show('modal-refund-all-campaign')
        this.all_campaign_refund = response.data
        this.refund_total = response?.total
        this.refund_credit = response?.total_credit_refund
      }).catch
      ((err) => {
        this.loadCampaign = false
        if (err?.response?.data?.code === 404) {
          this.warningRefundCampaign(this.$t('report.campaignList.modalRefundAllCredit.warningRefund'))
        } else {
          this.errorRefundCampaign(this.$t('common.somethingWrong') + '\n' + err?.response?.data?.code + ' : ' + err?.message)
        }
        this.closeCampaignModal()
      }).finally(() => {
        this.loadCampaign = false
        setTimeout(() => {
          this.grid = document.getElementById('grid-all-refund-campaign')
        }, 50)
      })
    },
    closeCampaignModal() {
      this.$bvModal.hide('modal-refund-all-campaign')
    },
    updateCredit() {
      for (let i = 1; i <= 10; i++) {
        setTimeout(() => {
          authApi.getCustomerProfile(true).then(resp => {
            if (resp?.codeSYS === '001') {
              // const total = resp?.sms_unit ? resp?.sms_unit : 0
              // store.dispatch('profile/setCredit', total)
              store.dispatch('profile/setProfileImg', resp?.image)
              store.dispatch('profile/setName', resp?.first_name + " " + resp?.last_name)
            }
          }).catch(err => {
            throw err
          })
        }, 2000 * i)
      }
    },
    sendAllCampaign() {
      this.buttonLoading = true
      reportApi.sendCampaignAllRefund({
        campaign_ids: this.all_campaign_refund,
        total_credit_refund: this.refund_credit
      }).then(response => {
        this.closeCampaignModal()
        this.updateCredit()
        this.$swal.fire({
          icon: 'success',
          title: this.$t('report.campaignList.modalRefundAllCredit.success'),
          html: '<p style="white-space: pre-wrap;">' + this.$t('report.campaignList.modalRefundAllCredit.textSuccess') + '</p>',
          confirmButtonColor: '#3085d6',
          confirmButtonText: this.$t('button.confirm'),
        })
      }).catch
      ((err) => {
        if (err?.response.status === 429) {
          let timer = Number(err.response.headers['ratelimit-reset'])
          let date = new Date()
          this.timer = date.setSeconds(date.getSeconds() + timer);
          this.warningRefundCampaign(this.$t('report.campaignList.modalRefundAllCredit.warningWrongTimeRefund'), this.$t('report.campaignList.modalRefundAllCredit.textManyCalls'), new Date(this.timer))
        } else if (err?.response?.data?.errors?.code == 502) {
          this.errorRefundCampaign(this.$t('report.campaignList.modalRefundAllCredit.textDestitute'))
          this.closeCampaignModal()
        } else {
          this.errorRefundCampaign(this.$t('common.somethingWrong') + '\n' + err?.response?.data?.code + ' : ' + err?.message)
          this.closeCampaignModal()
        }
      }).finally(() => {
        this.buttonLoading = false
      })
    },
    colorButton(data) {
      return functions.colorButtonStatusByText(data);
    },
    cancelCampaign(id) {
      this.$swal
          .fire({
            icon: "warning",
            title: this.$t("common.delete.topic"),
            text: this.$t("common.delete.detail"),
            showCancelButton: true,
            confirmButtonColor: "#EA5455",
            cancelButtonColor: "#7367F0",
            cancelButtonText: this.$t("button.cancel"),
            confirmButtonText: this.$t("button.confirm"),
          })
          .then((result) => {
            if (result.isConfirmed) {
              const data = {
                campaign_id: id
              }
              reportApi
                  .cancelCampaign(data)
                  .then((response) => {
                    if (response?.codeSYS === "001") {
                      this.findCampaigns();
                      this.getCredit();
                      alert.cancelSuccess(this);
                    } else {
                      alert.cancelFailed(this, response.message);
                    }
                  })
                  .catch((err) => {
                    alert.cancelFailed(this, err.response.data.message);
                  });
            }
          });
    },
    exportData() {
      this.$bvModal.show('modal-export-report')
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      const data = {
        file_type: "csv"
      }
      reportApi.exportCampaign(data, _.cloneDeep(this.form)).then(response => {
        if (response?.codeSYS === '001') {
          this.fileExport = response?.url_files
          this.exportSuccess = true
          this.step_success = true
        } else {
          this.exportSuccess = false
          this.step_success = false
        }
      }).catch(err => {
        this.$bvModal.hide('modal-export-report')
        alert.findFailed(this, err.response.data.message)
      })
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    changeNumberFormat(date) {
      return date ? functionsCommon.changeNumberFormat(date) : '-'
    },
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    viewDetail(id) {
      const data = {
        time1: moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss'),
        time2: moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss'),
        campaign_id: this.form.campaign_id,
        campaign_name: this.form.campaign_name,
        status: this.form.status,
        type: this.form.type,
      }
      const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
      const str = queryString.toString()
      this.$router.push(`/Reports/Campaigns/Detail?campaign=${id}&${str}`).catch(() => {
      })
    },
    getCredit() {
      authApi.getCustomerProfile(true).then(resp => {
        return resp
      }).catch(err => {
        throw err
      })
    },
    findCampaigns() {
      this.isBusy = !this.isBusy
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      reportApi.getCampaignAll(_.cloneDeep(this.form)).then(response => {
        this.totalAll = response.total;
        this.totalRows = response.total
        this.items = response.data

      }).finally(() => {
        this.isBusy = !this.isBusy
        this.form.$skip = _.cloneDeep(this.form.$skip)
      })
    },
    resetSearch() {
      this.form = {
        date_from: null,
        date_to: null,
        campaign_id: '',
        campaign_name: '',
        status: null,
        type: null,
        $skip: 1,
        $limit: 10,
      }
      this.time = [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ]
      this.$router.push(`/Reports/Campaigns`).catch(() => {
      })
      this.findCampaigns()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  },
  watch: {
    form: {
      handler(val) {
        const data = {
          date_from: moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss'),
          date_to: moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss'),
          campaign_id: val?.campaign_id || '',
          campaign_name: val?.campaign_name || '',
          status: val?.status || null,
          type: val?.type || null,
          email: val?.email || ''
        }
        const queryString = new URLSearchParams(functionsCommon.removeEmptyKey(data))
        this.strQuery = queryString.toString()
      },
      deep: true
    }
  }
};
</script>
<style lang="scss">
#modal-refund-all-campaign {
  .modal-refund-all-campaign__custom-grid-one {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
  }

  .modal-refund-all-campaign__custom-grid-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .flex-row-center {
    display: flex;
    justify-content: center;
    align-items: center
  }

  .flex-column-center {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .refund-campaign-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-font {
      font-size: 24px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }

    .sub-title-font {
      font-size: 18px;
      @media (max-width: 768px) {
        font-size: 16px;
      }

    }

    .grid-all-refund-campaign {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1px;
      width: 100%;
      height: auto;
      margin: 12px;
      padding: 5px 10px;
      overflow-x: scroll;

      .grid-refund-item {
        outline: 1px solid rgb(203 213 225);
        text-align: center;
        padding: 8px 24px;
      }
    }
  }

}

#reports-campaign-list {
  .flex-row-center {
    display: flex;
    justify-content: flex-start;
    align-items: center
  }

  .flex-column-center {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .custom-col-button {
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 18px;
    }
  }

  .action-button {
    cursor: pointer;
  }

  .campaign-timer {
    font-family: "Prompt Bold";
  }

}
</style>
